import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import styled from "styled-components";

import Layout from "../components/Layout/Layout";
import PageHeader from "../components/shared/PageHeader";
import StyledButton from "components/shared/StyledButton"

const StyledError = styled.div`
	width: 100%;
	min-height: 50vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	strong {
		font-size: 4rem;
	}
`

const NotFoundPage = () => {
	const { imageXs, imageSm, imageLg  } = useStaticQuery(graphql`
		query {
	imageXs: file(relativePath: { eq: "576.png" }) {
		childImageSharp {
			gatsbyImageData(
				width: 575,
				quality: 100
			)
		}
	}
	imageSm: file(relativePath: { eq: "991.png" }) {
		childImageSharp {
			gatsbyImageData(
				width: 991,
				quality: 100
			)
		}
	}
	imageLg: file(relativePath: { eq: "1920.png" }) {
		childImageSharp {
			 gatsbyImageData(
				width: 1740,
				quality: 100
			)
		}
	}
}

	`)
	return (
		
		<Layout title="404 | Back to the Future the Musical">
		<PageHeader
			imageXs={imageXs}
			imageSm={imageSm}
			imageLg={imageLg}
			title="404: Route Not Found" />
			
			<StyledError>
		<p> <strong> 404</strong> <br />
Page Not Found</p>
<p>The page you are looking for doesn't exist or has been moved.</p>
		<StyledButton href="/">
                                    Back to Home
                                </StyledButton>
			</StyledError>
	</Layout>
	)
}

export default NotFoundPage



